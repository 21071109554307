import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Heading } from '../Heading/Heading';
import { HeadingLevels } from '../Heading/HeadingTypes';
import { Linker as FallbackLinker } from '../Linker/Linker';
import styles from './TemplateHeader.module.css';

/**
 * @param {string}   title             Title to display on the left
 * @param {string}   subtitle          Subtitle to display on the left
 * @param {boolean}  displayBigTitle   Switch to have a bigger title
 * @param {object}   button            Link to display on the right
 * @param {object}   theme             Can contain specific styles
 * @param {boolean}  isFromDetail      Used to adjust styles
 * @param {boolean}  isFromShowcase    Used to adjust title styles
 * @param {boolean}  isTvDevice        Whether the device is a TV or not
 */
export function TemplateHeader({
  button,
  displayBigTitle,
  isFromDetail,
  isFromShowcase,
  isFromCreativeMedia,
  isTvDevice = false,
  Linker = FallbackLinker,
  subtitle,
  theme = {},
  title,
}) {
  return (
    <div className={classNames(styles.templateHeader, theme.templateHeader)} data-testid="template-header-container">
      <div className={styles.templateHeader__topContent}>
        <div
          className={classNames(
            styles.templateHeader__title,
            {
              [styles.templateHeader__title__showcase]: isFromShowcase,
              [styles['templateHeader__title--dark']]: isFromDetail || isFromShowcase || isFromCreativeMedia,
            },
            theme.templateHeader__title
          )}
        >
          <Heading text={title} isBig={displayBigTitle} isFromDetail={isFromDetail} isFromShowcase={isFromShowcase} />
        </div>

        {!isTvDevice && button && button.title && (
          <div className={styles.templateHeader__button}>
            <Linker
              data={{ mainOnClick: button.onClick }}
              title={button.title}
              className={classNames(styles.templateHeader__link, theme.templateHeader__link)}
              aria-label={`${button.title} ${title}`}
            >
              {button.title}
            </Linker>
          </div>
        )}
      </div>
      {subtitle && (
        <div
          className={classNames(
            styles.templateHeader__subtitle,
            {
              [styles.templateHeader__subtitle__showcase]: isFromShowcase,
              [styles.templateHeader__subtitle__truncate]: !isFromShowcase,
              [styles.templateHeader__subtitle__dark]: isFromDetail || isFromShowcase,
            },
            theme.templateHeader__subtitle
          )}
        >
          <Heading
            text={subtitle}
            level={HeadingLevels.LEVEL_3}
            isFromDetail={isFromDetail}
            isFromShowcase={isFromShowcase}
          />
        </div>
      )}
    </div>
  );
}

TemplateHeader.propTypes = {
  title: PropTypes.string.isRequired,
  displayBigTitle: PropTypes.bool.isRequired,
  theme: PropTypes.instanceOf(Object),
  isTvDevice: PropTypes.bool,
};
