import { HeadingContent } from './HeadingContent';
import { HeadingPlaceholder } from './HeadingPlaceholder';
import { HeadingProps } from './HeadingTypes';

// /**
//  * Heading
//  *
//  * renders a page title or its placeholder
//  */
export function Heading(props: HeadingProps): JSX.Element {
  const HeadingComponent = props?.text ? HeadingContent : HeadingPlaceholder;
  return <HeadingComponent {...props} />;
}
