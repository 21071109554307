export enum HeadingSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export enum HeadingLevels {
  LEVEL_1 = 1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  LEVEL_5,
  LEVEL_6,
}

export type HeadingProps = {
  text?: string;
  size?: HeadingSizes;
  level?: HeadingLevels;
  isBig?: boolean;
  isFromDetail?: boolean;
  isFromShowcase?: boolean;
  isFromCreativeMedia?: boolean;
};

export type HeadingHTMLTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
